import React from 'react';
import { StatusMessage, StatusMessageType } from '../StatusMessage/StatusMessage';
import * as classes from './ReportStage.module.scss';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { TargetAwareLink } from '../Link';
import { Vbr } from '../layout/Helpers';

const components = {
    a: <TargetAwareLink/>,
    br: <Vbr/>,
};

export const NoReportSection: React.FunctionComponent = () => {
    const { t } = useI18next('result');
    return (
        <div className={classes.stage}>
            <StatusMessage
                title={
                    <span>
                        <strong>{t('result:error.noTags.title')}</strong>
                        <br/>
                        <span>{t('result:error.noTags.subtitle')}</span>
                    </span>
                }
                status={StatusMessageType.error}>
                <Trans
                    ns="result"
                    i18nKey="error.noTags.text"
                    components={components}
                />
            </StatusMessage>
        </div>
    );
};
