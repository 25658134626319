import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StatusMessage, StatusMessageType } from '../StatusMessage/StatusMessage';
import * as classes from './ReportStage.module.scss';

export const NoResult: React.FunctionComponent = () => {
    const { t } = useTranslation('result');

    return (
        <div className={classes.stage}>
            <StatusMessage title={t('result:notFound.title')} status={StatusMessageType.error}>
                {t('result:notFound.description')}
            </StatusMessage>
        </div>
    );
};
