// extracted by mini-css-extract-plugin
export var breakpointXs = "550px";
export var colorGray = "#8e9287";
export var colorGrayDark = "#616161";
export var colorGrayDarker = "#272824";
export var colorGrayLight = "#e3e4e2";
export var colorGrayLighter = "#f2f3f2";
export var colorPrimary = "#a6ce3c";
export var colorSecondary = "#4f7802";
export var container = "DocumentInfo-module--container--85a58";
export var content = "DocumentInfo-module--content--dbf25";
export var full = "DocumentInfo-module--full--d586d";
export var half = "DocumentInfo-module--half--91039";
export var iconWrapper = "DocumentInfo-module--icon-wrapper--8dc33";
export var list = "DocumentInfo-module--list--7f6a8";
export var listWrapper = "DocumentInfo-module--list-wrapper--b177b";
export var spacingLg = "32px";
export var spacingMd = "32px";
export var spacingSm = "16px";
export var spacingXl = "128px";
export var spacingXs = "8px";
export var spacingXxs = "4px";
export var stat = "DocumentInfo-module--stat--27fa9";
export var title = "DocumentInfo-module--title--0249e";
export var wrapper = "DocumentInfo-module--wrapper--1eb4b";