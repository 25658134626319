import React from 'react';
import * as classes from './About.module.scss';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { linkStyles, TargetAwareLink } from '../Link';
import { Helpers } from '../layout';
import logoUri from '../../images/axes_Sense_logo.svg';

const components = {
    a: <TargetAwareLink {...linkStyles} />,
    span: <span/>,
    br: <Helpers.Vbr/>,
};

export const About: React.FunctionComponent = () => {
    const { t } = useI18next('result');
    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <Trans
                    i18nKey="about.text"
                    components={components}
                />
            </div>
            <div className={classes.imageWrapper}>
                <a
                    href={t('result:about.href')}
                    rel="noopener"
                    className={classes.imageLink}
                >
                    <img
                        lang="en"
                        className={classes.image}
                        src={logoUri}
                        alt={t('result:about.poweredByAlt')}
                    />
                </a>
            </div>
        </div>
    );
};
