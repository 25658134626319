import React, { RefObject, useRef } from 'react';
import {
    AriaOverlayProps,
    OverlayContainer,
    useModal,
    useOverlay,
    useOverlayPosition,
    useOverlayTrigger,
} from '@react-aria/overlays';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { FocusableElement } from '@react-types/shared';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { AriaButtonProps, useButton } from '@react-aria/button';
import * as classes from './InfoOverlay.module.scss';
import clsx from 'clsx';

export interface IInfoOverlayProps extends AriaOverlayProps, React.PropsWithChildren {
    title: string;
    ariaCloseButtonLabel: string;
    style?: React.CSSProperties;
}

const DismissButton = React.forwardRef<HTMLButtonElement, React.HTMLAttributes<HTMLButtonElement>>((props, ref) => {
    const { className, ...rest } = props;
    const finalClassName = clsx(classes.dismiss, className);
    return <button {...rest} className={finalClassName} ref={ref}/>;
});

const InfoOverlay = React.forwardRef<HTMLDivElement, IInfoOverlayProps>((props, ref) => {
    const {
        title,
        children,
        isOpen,
        onClose,
        style,
        ariaCloseButtonLabel,
        ...otherProps
    } = props;

    const { overlayProps } = useOverlay({
        isOpen: isOpen,
        isDismissable: true,
        onClose: onClose,
    }, ref as RefObject<FocusableElement>);

    const { modalProps } = useModal();

    const { titleProps, dialogProps } = useDialog({}, ref as RefObject<FocusableElement>);

    return (
        <FocusScope restoreFocus contain>
            <div
                {...mergeProps(overlayProps, dialogProps, otherProps, modalProps)}
                style={style}
                className={classes.box}
                ref={ref}
            >
                <h3 {...titleProps} className={classes.title}>{title}</h3>
                <div className={classes.content}>{children}</div>
                <DismissButton onClick={onClose} aria-label={ariaCloseButtonLabel}/>
            </div>
        </FocusScope>
    );
});

const InfoButton = React.forwardRef<HTMLButtonElement, AriaButtonProps>((props, ref) => {
    // @ts-ignore
    const { buttonProps } = useButton(props, ref);
    return (
        <button
            {...buttonProps}
            ref={ref}
            className={classes.trigger}
        ></button>
    );
});

interface IInfoOverlayTriggerProps {
    title: string;
    children: React.ReactNode;
    ariaCloseButtonLabel: string;
    triggerProps?: React.HTMLProps<HTMLButtonElement>;
}

export const InfoOverlayTrigger: React.FunctionComponent<IInfoOverlayTriggerProps> = props => {
    const state = useOverlayTriggerState({
        onOpenChange: isOpen => {
            document.body.style.overflow = isOpen ? 'hidden' : '';
        },
    });
    const triggerRef = useRef<HTMLButtonElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);

    const { overlayProps: positionProps, updatePosition } = useOverlayPosition({
        targetRef: triggerRef,
        overlayRef,
        placement: 'right',
        offset: 5,
        isOpen: state.isOpen,
    });

    const overlayTriggerState = { ...state, close: updatePosition };

    const { overlayProps, triggerProps } = useOverlayTrigger(
        { type: 'dialog' },
        overlayTriggerState,
        triggerRef
    );

    return (
        <>
            <InfoButton
                {...mergeProps(props.triggerProps ?? {}, triggerProps)}
                ref={triggerRef}
            >
                ?
            </InfoButton>
            {state.isOpen && (
                <OverlayContainer>
                    <InfoOverlay
                        {...overlayProps}
                        {...positionProps}
                        ref={overlayRef}
                        title={props.title}
                        ariaCloseButtonLabel={props.ariaCloseButtonLabel}
                        isOpen={state.isOpen}
                        onClose={state.close}
                    >
                        {props.children}
                    </InfoOverlay>
                </OverlayContainer>
            )}
        </>
    );
};
