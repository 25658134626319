import React from 'react';
import { AriaTabPanelProps } from '@react-types/tabs';
import { TabListState } from '@react-stately/tabs';
import * as classes from './tabs.module.scss';
import { useTabPanel } from '@react-aria/tabs';

export interface ITabPanelProps extends AriaTabPanelProps {
    state: TabListState<any>;
}

export const TabPanel: React.FunctionComponent<ITabPanelProps> = ({ state, ...props }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { tabPanelProps } = useTabPanel(props, state, ref);

    return (
        <div {...tabPanelProps} className={classes.tabPanel} ref={ref}>
            {state.selectedItem?.props.children}
        </div>
    );
};
