import React from 'react';
import { DocumentInformation as DocumentData } from '../../types/api';
import { useI18next } from 'gatsby-plugin-react-i18next';
import PdfIcon from '../Icons/pdf.svg';
import * as classes from './DocumentInfo.module.scss';
import clsx from 'clsx';
import { formatFilesize } from '../../util/files';

export interface IDocumentInfoProps {
    document: DocumentData;
    filename: string;
}


interface IDocumentStatProps {
    label: string;
    value?: string | null;
    half?: boolean;
}

const DocumentStat = (props: IDocumentStatProps) => {
    let value = props.value?.trim();
    value = value?.length ? value : undefined;
    return (
        <div className={clsx(classes.stat, props.half ? classes.half : classes.full)}>
            <dt>{props.label}:</dt>
            <dd>{value ?? '–'}</dd>
        </div>
    );
};

export const DocumentInfo: React.FunctionComponent<IDocumentInfoProps> = props => {
    const { t, i18n } = useI18next([ 'common', 'result' ]);

    const documentLanguage = props.document.language;
    const documentLanguageKey = 'common:languageSwitch.languages.' + documentLanguage?.substring(0, 2);
    const documentLanguageValue = i18n.exists(documentLanguageKey)
        ? t(documentLanguageKey)
        : documentLanguage ?? undefined;

    const tagStatus = t('result:documentInfo.stats.tags', {
        count: props.document.numberOfTags ?? 0,
    });

    const documentSize = props.document.sizeInKb
        ? formatFilesize(props.document.sizeInKb * 1024)
        : undefined;

    return (
        <section className={classes.container}>
            <h2 className={classes.title}>{t('result:documentInfo.title')}</h2>
            <div className={classes.wrapper}>
                <div className={classes.content}>
                    <div className={classes.iconWrapper} role="presentation">
                        <PdfIcon/>
                    </div>
                    <div className={classes.listWrapper}>
                        <dl className={classes.list}>
                            <DocumentStat
                                label={t('result:documentInfo.stats.title')}
                                value={props.document.title}
                            />
                            <DocumentStat
                                label={t('result:documentInfo.stats.filename')}
                                value={props.filename}
                            />
                            <DocumentStat
                                label={t('result:documentInfo.stats.language')}
                                value={documentLanguageValue}
                                half
                            />
                            <DocumentStat
                                label={t('result:documentInfo.stats.tagged')}
                                value={tagStatus}
                                half
                            />
                            <DocumentStat
                                label={t('result:documentInfo.stats.creator')}
                                value={props.document.creator}
                                half
                            />
                            <DocumentStat
                                label={t('result:documentInfo.stats.producer')}
                                value={props.document.producer}
                                half
                            />
                            <DocumentStat
                                label={t('result:documentInfo.stats.pages')}
                                value={(props.document.pageCount ?? 1).toString()}
                                half
                            />
                            <DocumentStat
                                label={t('result:documentInfo.stats.filesize')}
                                value={documentSize}
                                half
                            />
                        </dl>
                    </div>
                </div>
            </div>
        </section>
    );
};
