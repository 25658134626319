export const matchesAccept = (accept: string): (file: File) => boolean => {
    const accepted = accept.split(',').map(s => s.trim());
    return (file: File): boolean => {
        const fileName = file.name.split('?')[0];
        return accepted.some(fileTypeSpecifier => {
            if (fileTypeSpecifier.startsWith('.')) {
                return fileName.endsWith(fileTypeSpecifier);
            }
            const mimeTypeParts = fileTypeSpecifier.split('/');
            if (mimeTypeParts.length !== 2)
                return false;
            if (mimeTypeParts[1] === '*') {
                return file.type.startsWith(mimeTypeParts[0] + '/');
            }
            return file.type === fileTypeSpecifier;
        });
    };
};

const UNITS = [
    'B',
    'kB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB',
];

// see sindresorhus/pretty-bytes
export const formatFilesize = (bytes: number, locale?: string, precision = 3): string => {
    const exponent = Math.min(Math.floor(Math.log10(bytes) / 3), UNITS.length - 1);
    bytes /= 1024 ** exponent;
    const bytesString = bytes.toPrecision(precision);
    const numberString = Number(bytesString).toLocaleString(locale);
    const unit = UNITS[exponent];
    return `${numberString} ${unit}`;
};
