import React from 'react';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { Report } from '../../types/api';
import * as classes from './ReportStage.module.scss';
import { StatusMessage, StatusMessageType } from '../StatusMessage/StatusMessage';
import { linkStyles, TargetAwareLink } from '../Link';
import { Helpers } from '../layout';
import { InfoOverlayTrigger } from '../Overlay/InfoOverlay';

export interface IReportSectionProps {
    reports: Report[];
}


const descriptionComponents = {
    a: <TargetAwareLink {...linkStyles} />,
    br: <Helpers.Vbr/>,
    abbr: <abbr/>,
};

export const ReportStati: React.FunctionComponent<IReportSectionProps> = props => {
    const { t } = useI18next([ 'common', 'result' ]);

    return (
        <div className={classes.stage}>
            {props.reports.map((report, i) => {
                const status = (report.report.value.errorCount === 0) ? StatusMessageType.success : StatusMessageType.error;
                const typeKey = report.type === 'PDF/UA' ? 'pdfua' : 'wcag';
                return (
                    <StatusMessage
                        key={report.type}
                        title={(
                            <>
                                <span>
                                    {t('result:reportStatus.title', { report: report.report.value.caption })}
                                    &nbsp;
                                    <strong> {t(`result:reportStatus.${ status }.status`)}</strong>
                                </span>
                                <InfoOverlayTrigger
                                    title={t('common:infoOverlay.title', { topic: report.report.value.caption })}
                                    ariaCloseButtonLabel={t('common:infoOverlay.close')}
                                    triggerProps={{
                                        'aria-label': t('common:info', { about: report.report.value.caption }),
                                    }}
                                >
                                    {t(`result:reportStatus.info.${ typeKey }`)}
                                </InfoOverlayTrigger>
                            </>
                        )}
                        status={status}
                    >
                        <Trans
                            ns="result"
                            i18nKey={`reportStatus.${ status }.${ typeKey }`}
                            components={descriptionComponents}
                        />
                    </StatusMessage>
                );
            })}
        </div>
    );
};
