import React, { useCallback } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button } from '../Button';
import * as classes from './Permalink.module.scss';
import { useId } from '../../hooks/useId';

const copyToClipboard = (text: string): Promise<boolean> =>
    new Promise((res, rej) => {
        const inp = document.createElement('input');
        inp.value = text;
        inp.style.position = 'absolute';
        inp.style.left = '-9999999px';
        document.body.appendChild(inp);
        inp.select();
        const success = document.execCommand('copy');
        inp.remove();
        if (success) {
            res(success);
        } else {
            rej(success);
        }
    });

export const CopyPermalink: React.FunctionComponent<{ link: string, onCopy?: () => void }> = props => {
    const { t } = useTranslation('result');
    const inputId = useId('copyLink');

    const handleCopy = useCallback(() => {
        props.onCopy?.();
        if ('canShare' in navigator && typeof navigator.canShare === 'function' && navigator.canShare({
            url: props.link,
        })) {
            navigator.share({
                url: props.link,
            });
        } else {
            copyToClipboard(props.link);
        }
    }, [ props.link, props.onCopy ]);

    const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
    }, []);

    return (
        <div className={classes.wrapper}>
            <label htmlFor={inputId}>{t('result:permalink.title')}</label>
            <div className={classes.comboInput}>
                <input
                    readOnly
                    id={inputId}
                    value={props.link}
                    className={classes.input}
                    onFocus={handleFocus}
                />
                <Button
                    variant="primary"
                    onClick={handleCopy}
                    className={classes.button}
                    size="small"
                >
                    {t('result:permalink.button')}
                </Button>
            </div>
        </div>

    );
};
