import React from 'react';
import * as classes from './ApplicationTitle.module.scss';
import logoUri from '../../images/axesCheck_logo_horizontal.svg';

export interface IApplicationTitleProps {
    title: string;
    withoutLogo?: boolean;
    /**
     * visually hides the logo, i.e. removes the alt-text
     */
    hideLogo?: boolean;
}

export const ApplicationTitle: React.FunctionComponent<IApplicationTitleProps> = props => {
    const logoAlt = props.hideLogo ? '' : 'axesCheck ';

    return (
        <h1 className={classes.title}>
            {props.withoutLogo !== true && (
                <img alt={logoAlt} lang="en" src={logoUri} className={classes.logo} />
            )}
            <span>{props.title}</span>
        </h1>
    );
};
