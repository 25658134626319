import React, { useRef } from 'react';
import { useTabListState } from '@react-stately/tabs';
import { Tab } from './tab';
import { TabListProps } from '@react-types/tabs';
import { TabPanel } from './tab-panel';
import * as classes from './tabs.module.scss';
import { useTabList } from '@react-aria/tabs';

export interface ITabsProps extends TabListProps<object> {
}

export const Tabs: React.FunctionComponent<ITabsProps> = props => {
    const state = useTabListState(props);
    const ref = useRef<HTMLDivElement>(null);
    const { tabListProps } = useTabList(props, state, ref);
    return (
        <div className={classes.tabs}>
            <div
                {...tabListProps}
                ref={ref}
                className={classes.tabList}
            >
                {[ ...state.collection ].map(item => (
                    <Tab
                        key={item.key}
                        item={item}
                        state={state}
                    />
                ))}
            </div>
            <div className={classes.tabPanelContainer}>
                <TabPanel key={state.selectedItem?.key} state={state}/>
            </div>
        </div>
    );
};
