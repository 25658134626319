// extracted by mini-css-extract-plugin
export var breakpointXs = "550px";
export var colorGray = "#8e9287";
export var colorGrayDark = "#616161";
export var colorGrayDarker = "#272824";
export var colorGrayLight = "#e3e4e2";
export var colorGrayLighter = "#f2f3f2";
export var colorPrimary = "#a6ce3c";
export var colorSecondary = "#4f7802";
export var creationDate = "[---]-module--creation-date--70f4c";
export var loadingIndicator = "[---]-module--loading-indicator--8ff59";
export var main = "[---]-module--main--befab";
export var noResultButton = "[---]-module--no-result-button--03a8c";
export var reportContainer = "[---]-module--reportContainer--82c10";
export var section = "[---]-module--section--41bb9";
export var spacingLg = "32px";
export var spacingMd = "32px";
export var spacingSm = "16px";
export var spacingXl = "128px";
export var spacingXs = "8px";
export var spacingXxs = "4px";