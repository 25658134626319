// extracted by mini-css-extract-plugin
export var breakpointXs = "550px";
export var colorGray = "#8e9287";
export var colorGrayDark = "#616161";
export var colorGrayDarker = "#272824";
export var colorGrayLight = "#e3e4e2";
export var colorGrayLighter = "#f2f3f2";
export var colorPrimary = "#a6ce3c";
export var colorSecondary = "#4f7802";
export var spacingLg = "32px";
export var spacingMd = "32px";
export var spacingSm = "16px";
export var spacingXl = "128px";
export var spacingXs = "8px";
export var spacingXxs = "4px";
export var tab = "tabs-module--tab--09efd";
export var tabDisabled = "tabs-module--tabDisabled--7e8fc";
export var tabList = "tabs-module--tabList--04e1b";
export var tabPanelContainer = "tabs-module--tabPanelContainer--e793a";
export var tabSelected = "tabs-module--tabSelected--4278c";
export var tabs = "tabs-module--tabs--812ef";