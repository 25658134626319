import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Copy, Headings } from '../Text';
import { Tab, Tabs } from '../tabs';
import { ReportTable } from './ReportTable';
import { Report } from '../../types/api';
import * as classes from './Result.module.scss';
import { useId } from '../../hooks/useId';

export interface IReportSectionProps {
    reports: Report[];
    reportLanguage: string;
}

export const ReportSection: React.FunctionComponent<IReportSectionProps> = props => {
    const { t, i18n } = useI18next('report');
    const tabsLabel = useId();
    return (
        <section className={classes.section}>
            <Headings.H2>{t('result:reportSection.title')}</Headings.H2>
            <Copy id={tabsLabel}>
                <p>{t('result:reportSection.chooseReportType')}</p>
            </Copy>
            <Tabs aria-labelledby={tabsLabel}>
                {props.reports.map(r => {
                    const title = i18n.exists(`result:reportTable.labels.${ r.type }.title`)
                        ? t(`result:reportTable.labels.${ r.type }.title`)
                        : r.report.value.caption;
                    return (
                        <Tab
                            key={r.type}
                            title={title}
                        >
                            <ReportTable report={r} reportLanguage={props.reportLanguage}/>
                        </Tab>
                    );
                })}
            </Tabs>
        </section>
    );
};
