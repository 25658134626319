import React from 'react';
import clsx from 'clsx';
import * as classes from './StatusMessage.module.scss';
import SuccessIcon from '../Icons/success.svg';
import ErrorIcon from '../Icons/error.svg';

export enum StatusMessageType {
    success = 'success',
    error = 'error',
}

export interface IStatusMessageProps {
    title: React.ReactNode;
    status: StatusMessageType;
    children: React.ReactNode;
    className?: string;
}

export const StatusMessage = React.forwardRef<HTMLDivElement, IStatusMessageProps>((props, ref) => {
    const IconComponent = props.status === StatusMessageType.error ? ErrorIcon : SuccessIcon;
    return (
        <div className={clsx(props.className, classes.message, classes[props.status])} ref={ref}>
            <div className={classes.icon} role="presentation">
                <IconComponent/>
            </div>
            <div className={classes.messageContent}>
                <span className={classes.title}>{props.title}</span>
                <div>{props.children}</div>
            </div>
        </div>
    );
});
