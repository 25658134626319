import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Router } from '@reach/router';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { getSummary as fetchSummary } from '../../util/api';
import { ErrorResponse } from '../../types/api';
import { NoResult } from '../../components/result/no-result';
import { useReportData } from '../../util/report-data';
import { graphql } from 'gatsby';
import { Layout, Section, SectionSpacing, SectionVariant } from '../../components/layout';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { SiteViewTrackingOptions } from '../../matomo';
import { DocumentInfo } from '../../components/result/DocumentInfo';
import { ReportSection } from '../../components/result/ReportSection';
import { CopyPermalink } from '../../components/result/Permalink';
import * as classes from './[...].module.scss';
import { ApplicationTitle } from '../../components/layout/ApplicationTitle';
import { DateFormat } from '../../util/date';
import { ReportStati } from '../../components/result/ReportStati';
import { About } from '../../components/result/About';
import { Seo } from '../../components/Seo';
import { NoReportSection } from '../../components/result/NoReportSection';
import { Button } from '../../components/Button';
import { TargetAwareLink } from '../../components/Link';
import { Spinner, SpinnerSize } from '../../components/loading';
import { Stack } from '../../components/Stack/Stack';

interface IResultProps {
    jobId?: string;
    path: string;
}

const Result: React.FunctionComponent<IResultProps> = (props) => {
    const { trackEvent } = useMatomo();
    const { t, language } = useI18next('result');
    const jobId = props.jobId ?? '';

    const { getSummary, addSummary, knownSummaries: existingSummaries } = useReportData();
    const summary = getSummary(jobId);
    const knownSummaries = useRef(existingSummaries);
    const [ error, setError ] = useState<ErrorResponse | null>(null);
    const [ loading, setLoading ] = useState(!knownSummaries.current.has(jobId));
    const [ hasLoaded, setHasLoaded ] = useState(false);
    const hasSummary = !loading && !!summary;
    const noSummary = hasLoaded && !summary;

    const [ permalink, setPermalink ] = useState('');
    useEffect(() => {
        setPermalink(document.location.origin + document.location.pathname);
    }, [ jobId ]);

    useEffect(() => {
        if (knownSummaries.current.has(jobId)) {
            return;
        }
        setError(null);
        setLoading(true);
        fetchSummary(jobId)
            .then(summary => {
                knownSummaries.current.add(jobId);
                addSummary(jobId, summary);
            })
            .catch(error => {
                setError(error);
            })
            .then(() => {
                setLoading(false);
                setHasLoaded(true);
            });
    }, [ addSummary, jobId ]);

    const title = t('result:title');

    const siteViewParameters = useMemo<SiteViewTrackingOptions>(() => ({
        title,
        url: noSummary ? `/${ language }/result/not-found` : `/${ language }/result/id`,
    }), [ title, language, noSummary ]);

    const onCopy = useCallback(() => {
        trackEvent({
            href: siteViewParameters ? siteViewParameters.url : '/',
            category: 'testResult',
            action: 'copyPermalink',
        });
    }, [ trackEvent, siteViewParameters ]);

    const creationDate = summary?.body.creationDate
        ? (new Date(summary?.body.creationDate)).toLocaleString(language, DateFormat.full)
        : undefined;

    return (
        <Layout
            title={title}
            trackSiteView={siteViewParameters}
            className={classes.main}
            seoProps={{
                keywords: t('common:meta.keywords'),
                description: t('common:meta.description'),
            }}
        >

            <Seo.Meta noIndex/>

            <Section variant={SectionVariant.DarkGreen} spacing={SectionSpacing.Medium}
                className={classes.section}>
                <div className={classes.reportContainer}>
                    <div>
                        <ApplicationTitle title={title} hideLogo/>
                        {creationDate && (
                            <div className={classes.creationDate}>
                                <strong>{t('result:created')}</strong> {creationDate}</div>
                        )}
                    </div>

                    {loading && <div>
                        <Stack align="center" justify="center" className={classes.loadingIndicator}>
                            <Spinner size={SpinnerSize.big} label={t('result:loading')}/>
                        </Stack>
                    </div>}

                    {hasSummary && <>
                        {summary?.body.documentInformation.isTagged ? (
                            <ReportStati reports={summary?.body.reports}/>
                        ) : (
                            <NoReportSection/>
                        )}

                        <div>
                            <CopyPermalink link={permalink} onCopy={onCopy}/>
                            <DocumentInfo
                                document={summary?.body.documentInformation}
                                filename={summary?.body.name}
                            />
                            {summary?.body.documentInformation.isTagged && (
                                <ReportSection
                                    reports={summary.body.reports}
                                    reportLanguage={summary?.body.documentInformation.reportLanguage}
                                />
                            )}
                        </div>
                    </>}

                    {error && (
                        <NoResult/>
                    )}

                    <div className={classes.noResultButton}>
                        {/* @ts-ignore */}
                        <Button as={TargetAwareLink} href="/">
                            {t('result:newScan')}
                        </Button>
                    </div>

                    <About/>
                </div>
            </Section>
        </Layout>
    );
};

const ResultContainer = () => {
    return (
        <Router basepath="/:lang/result/">
            <Result path="/:jobId"/>
        </Router>
    );
};

export default ResultContainer;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
