import { Report, ReportGroupSummary } from '../../types/api';
import React, { ThHTMLAttributes } from 'react';
import { useId } from '../../hooks/useId';
import SuccessIcon from '../Icons/success.svg';
import ErrorIcon from '../Icons/error.svg';
import SuccessInvertedIcon from '../Icons/success_inverted.svg';
import ErrorInvertedIcon from '../Icons/error_inverted.svg';
import * as classes from './ReportTable.module.scss';
import clsx from 'clsx';
import { TFunction, useI18next } from 'gatsby-plugin-react-i18next';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { InfoOverlayTrigger } from '../Overlay/InfoOverlay';
import { i18n } from 'i18next';

export interface IReportTableProps {
    report: Report;
    reportLanguage: string;
}

const hasErrors = (result: ReportGroupSummary) =>
    result.errorCount + result.warningCount > 0;

interface IStatusThProps extends ThHTMLAttributes<HTMLTableHeaderCellElement> {
    labelId?: string;
    passed: boolean;
    t: TFunction;
    inverted?: boolean;
    help?: React.ReactNode;
    language?: string;
}

const StatusTh: React.FunctionComponent<IStatusThProps> = props => {
    const { passed, children, inverted, id, t, help, language, labelId, ...rest } = props;
    const Icon = passed
        ? inverted ? SuccessInvertedIcon : SuccessIcon
        : inverted ? ErrorInvertedIcon : ErrorIcon;
    const statusIconClass = clsx(classes.statusIcon, passed ? classes.statusIconOk : classes.statusIconError);

    const langProps = language !== undefined ? { lang: language } : {};

    return (
        <th {...rest} id={id}>
            <span className={classes.statusHeader}>
                <span aria-hidden="true" className={statusIconClass}><Icon/></span>
                <span id={labelId} {...langProps}>{children}</span>
                <VisuallyHidden
                    elementType="span">{t('result:reportTable.status.' + (passed ? 'passed' : 'errors'))}</VisuallyHidden>
                {help}
            </span>
        </th>
    );
};

interface ICriterionRowProps {
    t: TFunction;
    i18n: i18n;
    index: number;
    categoryId: string;
    passedId: string;
    warningId: string;
    errorId: string;
    data: ReportGroupSummary;
    isLast?: boolean;
    reportLanguage: string;
}

const CriterionRow: React.FunctionComponent<ICriterionRowProps> = props => {
    const passed = !hasErrors(props.data);
    const thId = `${ props.categoryId }-th${ props.index }`;
    const isLabelTranslated = props.i18n.exists(`result:reportTable.labels.${ props.data.id }.title`);
    return (
        <tr
            className={clsx(classes.criterionRow, props.isLast && classes.sectionEnd)}
        >
            <StatusTh
                id={thId}
                passed={passed}
                t={props.t}
                headers={props.categoryId}
                className={classes.criterionCol}
                language={isLabelTranslated ? undefined : props.reportLanguage}
                scope="row"
            >
                {isLabelTranslated
                    ? props.t(`result:reportTable.labels.${ props.data.id }.title`)
                    : props.data.caption
                }
            </StatusTh>
            <td
                headers={`${ props.categoryId } ${ thId } ${ props.passedId }`}
                className={classes.valueCell}
            >
                {props.data.passedCount}
            </td>
            <td
                headers={`${ props.categoryId } ${ thId } ${ props.warningId }`}
                className={classes.valueCell}
            >
                {props.data.warningCount}
            </td>
            <td
                headers={`${ props.categoryId } ${ thId } ${ props.errorId }`}
                className={classes.valueCell}
            >
                {props.data.errorCount}
            </td>
        </tr>
    );
};

export const ReportTable: React.FunctionComponent<IReportTableProps> = props => {
    const { t, i18n } = useI18next([ 'common', 'result' ]);
    const categories = props.report.report.children;

    const passedId = useId('statuscell');
    const warningId = useId('statuscell');
    const errorId = useId('statuscell');

    return (
        <div className={classes.overflowContainer}>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <td></td>
                        <th
                            className={classes.columnHeader}
                            id={passedId}
                            scope="col"
                        >
                            {t('result:reportTable.columns.passed')}
                        </th>
                        <th
                            className={classes.columnHeader}
                            id={warningId}
                            scope="col"
                        >
                            {t('result:reportTable.columns.warnings')}
                        </th>
                        <th
                            className={classes.columnHeader}
                            id={errorId}
                            scope="col"
                        >
                            {t('result:reportTable.columns.failures')}
                        </th>
                    </tr>
                </thead>
                {categories.map((category, i) => {
                    const passed = !hasErrors(category.value);
                    const categoryId = `categorycell${ i }`;
                    const labelId = `${ categoryId }-lbl`;
                    const isLabelTranslated = i18n.exists(`result:reportTable.labels.${ category.value.id }.title`);
                    const translatedGroupLabel = isLabelTranslated
                        ? t(`result:reportTable.labels.${ category.value.id }.title`)
                        : category.value.caption;

                    return (
                        <tbody key={i} className={classes.tableSection}>
                            <tr className={classes.sectionRow}>
                                <StatusTh
                                    id={categoryId}
                                    labelId={labelId}
                                    colSpan={4}
                                    t={t}
                                    passed={passed}
                                    inverted
                                    scope="colgroup"
                                    language={isLabelTranslated ? undefined : props.reportLanguage}
                                    help={i18n.exists(`result:reportTable.labels.${ category.value.id }.description`) && (
                                        <InfoOverlayTrigger
                                            title={t('common:infoOverlay.title', { topic: translatedGroupLabel })}
                                            ariaCloseButtonLabel={t('common:infoOverlay.close')}
                                            triggerProps={{
                                                'aria-label': t('common:info', { about: translatedGroupLabel }),
                                            }}
                                        >
                                            {t(`result:reportTable.labels.${ category.value.id }.description`)}
                                        </InfoOverlayTrigger>
                                    )}
                                >
                                    {translatedGroupLabel}
                                </StatusTh>
                            </tr>
                            {(category.children || []).map((criteria, j, a) => (
                                <CriterionRow
                                    key={j}
                                    t={t}
                                    i18n={i18n}
                                    index={j}
                                    categoryId={categoryId}
                                    passedId={passedId}
                                    warningId={warningId}
                                    errorId={errorId}
                                    data={criteria.value}
                                    isLast={a.length - j === 1}
                                    reportLanguage={props.reportLanguage}
                                />
                            ))}
                        </tbody>
                    );
                })}
            </table>
        </div>
    );
};
