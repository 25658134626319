// extracted by mini-css-extract-plugin
export var breakpointXs = "550px";
export var colorGray = "#8e9287";
export var colorGrayDark = "#616161";
export var colorGrayDarker = "#272824";
export var colorGrayLight = "#e3e4e2";
export var colorGrayLighter = "#f2f3f2";
export var colorPrimary = "#a6ce3c";
export var colorSecondary = "#4f7802";
export var columnHeader = "ReportTable-module--column-header--2ceb1";
export var criterionCol = "ReportTable-module--criterionCol--4ec0f";
export var criterionRow = "ReportTable-module--criterionRow--3ddd7";
export var fg = "ReportTable-module--fg--51a61";
export var overflowContainer = "ReportTable-module--overflow-container--17f93";
export var sectionRow = "ReportTable-module--sectionRow--13e63";
export var spacingLg = "32px";
export var spacingMd = "32px";
export var spacingSm = "16px";
export var spacingXl = "128px";
export var spacingXs = "8px";
export var spacingXxs = "4px";
export var statusHeader = "ReportTable-module--statusHeader--bb3ed";
export var statusIcon = "ReportTable-module--statusIcon--2279e";
export var statusIconError = "ReportTable-module--statusIconError--902b7";
export var statusIconOk = "ReportTable-module--statusIconOk--967f0";
export var table = "ReportTable-module--table--fe742";
export var tableSection = "ReportTable-module--table-section--1ea3a";
export var valueCell = "ReportTable-module--value-cell--290b4";