import React, { useMemo, useRef } from 'react';
import { TabListState } from '@react-stately/tabs';
import { Node } from '@react-types/shared';
import * as classes from './tabs.module.scss';
import clsx from 'clsx';
import { useTab } from '@react-aria/tabs';

export interface ITabProps<T> {
    state: TabListState<unknown>;
    item: Node<T>;
}

export const Tab: React.FunctionComponent<ITabProps<unknown>> = ({ item, state }) => {
    const { key, rendered } = item;
    const ref = useRef<HTMLDivElement>(null);
    const { tabProps } = useTab({ key, shouldSelectOnPressUp: true }, state, ref);
    const isSelected = state.selectedKey === key;
    const isDisabled = state.disabledKeys.has(key);

    const className = useMemo(() => clsx(
        classes.tab,
        isSelected && classes.tabSelected,
        isDisabled && classes.tabDisabled,
    ), [ isSelected, isDisabled ]);

    return (
        <div
            {...tabProps}
            ref={ref}
            className={className}
        >
            {rendered}
        </div>
    );
};
